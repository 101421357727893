<template>
  <section>
    <Deliveries :isOpen.sync="showDeliveryModal" />
    <Portal to="headerActions">
      <Button
        type="is-primary"
        :label="$t('schedule.newDelivery')"
        icon-left="plus"
        @click="() => (showDeliveryModal = true)"
        :permission="Permission.create"
      />
    </Portal>
    <Card id="schedule" />
  </section>
</template>

<script>
import { Button } from '@/components';
import Card from '@/components/Schedule/Card.vue';
import { Deliveries } from '@/views/Fragments';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    Button,
    Card,
    Deliveries
  },
  data() {
    return {
      reloadTable: false,
      showDeliveryModal: false
    };
  },
  mounted() {
    this.$store.dispatch('setBreadcumbs', this.breadcumbs);
  },
  computed: {
    breadcumbs() {
      return ['menu.sch'];
    },
    Permission() {
      return Permissions.Scheduling;
    }
  }
};
</script>

<style lang="sass" scoped>
#schedule
  margin: 0
</style>
