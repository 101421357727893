<template>
  <div id="lunchBreakModal">
    <Modal
      :isOpen.sync="isModalOpen"
      :loading="{ save: loading }"
      :header="$t('modal.add', [$t('schedule.lunchBreak')])"
      allowOverflow
      size="is-medium"
      @cancel="() => {}"
      @save="onSubmit"
    >
      <MessageBadge :message="$t('schedule.lunchBreakHours')" type="is-warning" />
      <form ref="form" @submit.prevent="onSubmit">
        <Field :label="$t('dispatch.deliverySequence')">
          <b-radio
            v-for="(option, i) in sequenceOptions"
            :key="i"
            type="primary"
            :value="secuence > 1 ? 0 : secuence"
            :native-value="option.value"
            @input="
              (value) => {
                secuence = value == 0 ? 5 : value;
              }
            "
          >
            {{ option.label }}
          </b-radio>
        </Field>
        <Field :label="$t('global.place')" v-if="secuence > 1">
          <b-select placeholder="" v-model="secuence">
            <option
              v-for="option in [2, 3, 4, 5, 6, 7, 8, 9]"
              :value="option"
              :key="`opt-${option}`"
            >
              {{ `${$t('global.place')} ${option}` }}
            </option>
          </b-select>
        </Field>
      </form>
    </Modal>
  </div>
</template>

<script>
import { toast } from '@/utils/dialog';
import { Modal, Field, MessageBadge } from '@/components';

export default {
  components: {
    Modal,
    Field,
    MessageBadge
  },
  created() {
    this.unsubscribe = this.$store.subscribe(({ type }, { schedule: { currentManifest } }) => {
      if (type == 'schedule/currentManifest') {
        this.manifest = currentManifest;
      }
    });
  },
  destroyed() {
    this.unsubscribe();
  },
  computed: {
    sequenceOptions() {
      return [
        { value: 1, label: this.$t('dispatch.moveQueue') },
        { value: 0, label: this.$t('dispatch.moveSpecificPlace') }
      ];
    }
  },
  data() {
    return {
      isModalOpen: false,
      loading: false,
      manifest: null,
      secuence: 1,
      unsubscribe: null
    };
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      try {
        const params = { delivery_event_type_id: 4 };
        if (this.secuence != 1) params.secuence = this.secuence;
        await this.Api.post(`delivery_manifest/${this.manifest.id}/delivery_events`, params);
        toast('success', this.$t('messages.saved'), 5000);
        this.$store.commit('schedule/lunchBreak', true);
        this.isModalOpen = false;
        this.$emit('saved');
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    }
  },
  watch: {
    isOpen(value) {
      this.isModalOpen = value;
    },
    isModalOpen(value) {
      this.$emit('update:isOpen', value);
    }
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="sass" scoped>
#lunchBreakModal
  form
    margin-bottom: 15px
.sub-title
  font-size: $f-lg
  font-weight: bold
  margin-top: 24px
.b-radio
  margin-top: 25px
  margin-bottom: 10px
.warning
  align-content: center
  background: $orange-100 !important
  border-left: 4px solid $orange-800
  border-radius: 4px 4px 4px 4px
  color: $orange-800
  display: flex
  font-size: 16px
  font-weight: 400
  margin-bottom: 0.8rem
  max-width: 490px
  padding: 0.563rem
  .icon
    align-content: center
    display: flex
    margin-right: 0.25rem
</style>
