<template>
  <div class="filters f-ai">
    <NewLunchBreak :isOpen.sync="lunchBreak.showModal" @saved="() => $emit('savedLunchBreak')" />
    <div class="flex f-ai f-full f-jsb">
      <DatePicker
        class="datepicker"
        :label="$t('schedule.search.date')"
        v-model="params.date"
        :min-date="minDate"
        icon-right="calendar-today"
        :disabled="disabled"
      />
      <Autocomplete
        :append="[{ id: 'all', name: $t('global.all') }]"
        :api="{
          full: true,
          url: 'vehicle_groups',
          params: [{ id: 'is_active', value: 1 }]
        }"
        :disabled="disabled"
        :label="$t('vehicle.group')"
        field="name"
        model="id"
        v-model="params.vehicle_group_id"
        @select="onVehicleGroupIdSelect"
      />
      <Autocomplete
        :disabled="disabled || !filteredRuns.length"
        :label="$t('runs.label')"
        accentColor="#5265F7"
        field="name"
        model="id"
        :data="filteredRuns"
        v-model="params.driver_run_id"
        :loading="loading.filters"
      >
        <template #results="{ data }">
          <div class="w-100">
            <strong>{{ data.name }}</strong>
            <p>{{ data.driver_name }}</p>
          </div>
        </template>
      </Autocomplete>
      <iSwitch
        class="mb-3"
        style="align-self: flex-end"
        :text="$t('global.showAll')"
        :disabled="disabled"
        v-model="showAll"
      />
    </div>
    <div class="search-container flex f-jce">
      <!-- <Button
        class="ml-1"
        type="is-primary"
        :loading="loading.scheduling"
        :tooltip="$t('schedule.automaticDeliveryAssignment')"
        icon-left="calendar-sync"
        :disabled="disabled"
        @click="onAutomaticDeliveryAssignment"
        :permission="Permission.automaticScheduling"
      /> -->
      <Input-search
        class="search-field ml-2"
        icon="magnify"
        v-model="params.all"
        :placeholder="$t('schedule.search.title')"
        :timeout="500"
      />
      <!-- <Button
        class="ml-2 is-visible-xl"
        type="is-primary"
        :label="$t('schedule.addLunch')"
        icon-left="silverware-fork-knife"
        :disabled="lunchBreak.disabledButton"
        @click="lunchBreak.showModal = true"
        :permission="Permission.assign"
      /> -->
      <RecalculateManifestRoute
        class="is-visible-xl"
        compact
        @update="$emit('recalculateManifestUpdate')"
      />
    </div>
  </div>
</template>

<script>
import NewLunchBreak from './NewLunchBreak.vue';
import { Permissions } from '@/utils/Secure';
import {
  Autocomplete,
  // Button,
  DatePicker,
  InputSearch,
  iSwitch,
  RecalculateManifestRoute
} from '@/components';
import { confirm, toast } from '@/utils/dialog';
import { uniq } from 'ramda';

export default {
  components: {
    Autocomplete,
    // Button,
    DatePicker,
    InputSearch,
    iSwitch,
    NewLunchBreak,
    RecalculateManifestRoute
  },
  created() {
    this.params.date = this.$moment(new Date()).format('Y-MM-DD');
    this.unsubscribe = this.$store.subscribe(
      ({ type }, { schedule: { currentManifest, lunchBreak, manifests } }) => {
        if (type == 'schedule/currentManifest') {
          this.manifest = currentManifest;
          this.params.driver_run_id = currentManifest.driver_run_id;
        }
        if (type == 'schedule/manifests') {
          this.manifestsRunsIds = uniq(manifests.map((manifest) => manifest.driver_run_id));
          if (!this.params.driver_run_id) this.params.driver_run_id = this.manifestsRunsIds[0];
        }
        if (type == 'schedule/lunchBreak') {
          if (this.manifest.delivery_manifest_status_id !== 4)
            this.lunchBreak.disabledButton = !!lunchBreak;
        }
      }
    );
  },
  destroyed() {
    this.unsubscribe();
  },
  mounted() {
    this.getRuns();
  },
  data() {
    return {
      manifestsRunsIds: [],
      runs: [],
      loading: { filters: false, scheduling: false },
      lunchBreak: { showModal: false, disabledButton: true },
      manifest: null,
      params: {
        vehicle_group_id: 'all',
        driver_run_id: 0,
        date: null,
        all: null
      },
      showAll: true,
      unsubscribe: null
    };
  },
  computed: {
    minDate() {
      return this.$moment(new Date()).subtract(1, 'days').toDate();
    },
    filteredRuns() {
      const runs = [];
      this.runs.map((run) => {
        this.manifestsRunsIds.map((manifestRunId) => {
          if (run.id == manifestRunId) runs.push(run);
        });
      });
      return runs;
    },
    searchParams() {
      const { date, vehicle_group_id, driver_run_id, all } = this.params;
      if (!vehicle_group_id || !driver_run_id) return { date };
      const result = { date, vehicle_group_id, driver_run_id };
      if (all) result.all = all;
      return result;
    },
    Permission() {
      return Permissions.Scheduling;
    }
  },
  methods: {
    async onAutomaticDeliveryAssignment() {
      confirm({
        message: `<b>${this.$t('schedule.automaticDeliveryAssignment')}</b><br /><br/><p>${this.$t(
          'schedule.confirm.automaticScheduling'
        )}</p>`,
        cancelText: this.$t('confirm.no'),
        confirmText: this.$t('confirm.yes'),
        onConfirm: async () => {
          this.onConfirmAutomaticDeliveryAssignment();
        }
      });
    },
    async onConfirmAutomaticDeliveryAssignment() {
      this.loading.scheduling = true;
      try {
        let promises = [];
        let { data } = await this.Api.get(
          `delivery_manifest/automatic_scheduling?date=${this.params.date}`
        );
        if (data.cdp_list) {
          data.cdp_list.map((cdp_id) => {
            promises.push(this.automaticScheduling(cdp_id));
          });
          Promise.all(promises).then(() => {
            toast('success', this.$t('schedule.messages.scheduling'), 5000);
            this.$emit('scheduling', promises);
            this.loading.scheduling = false;
          });
        }
      } catch (error) {
        console.error(error);
        this.loading.scheduling = false;
      }
    },
    async automaticScheduling(cdp_id) {
      try {
        const { data } = await this.Api.post('delivery_manifest/automatic_scheduling', {
          date: this.params.date,
          cdp_id
        });
        return data;
      } catch (error) {
        console.error(error);
      }
    },
    async getRuns() {
      this.loading.filters = true;
      try {
        const { vehicle_group_id } = this.params;
        let query = '';
        if (vehicle_group_id != 'all') query = `&vehicle_group_id=${vehicle_group_id}`;
        const { data } = await this.Api.get(`driver_runs?per_page=9999${query}`);
        this.runs = data;
      } catch (error) {
        console.error(error);
      }
      this.loading.filters = false;
    },
    onVehicleGroupIdSelect(vehicle_group_id) {
      this.params.vehicle_group_id = vehicle_group_id;
      this.getRuns();
    }
  },
  watch: {
    manifest(manifest) {
      this.lunchBreak.disabledButton = manifest.delivery_manifest_status_id == 4;
    },
    searchParams(value) {
      this.$store.commit('schedule/filters', value);
    },
    'params.date'(value) {
      if (this.showAll) this.showAll = false;
      this.$emit('input', { all: this.params.all, delivery_date: value });
    },
    'params.all'(value) {
      if (!this.showAll) this.$emit('input', { all: value, delivery_date: this.params.date });
      else this.$emit('input', { all: value });
    },
    showAll(value) {
      this.$emit(
        'input',
        value ? { all: this.params.all } : { all: this.params.all, delivery_date: this.params.date }
      );
    }
  },
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: Object, default: () => {} },
    isLargeScreen: { type: Boolean, default: false }
  }
};
</script>

<style lang="sass" scoped>
.filters
  position: relative
  z-index: 40
  margin-bottom: 20px
  .datepicker
    width: calc(20% - 0.2rem)
  .iSwitch
    width: calc(20% - 0.2rem)
  .autocomplete,
  .search-field
    width: calc(30% - 0.5rem)
  ::v-deep .button .mdi-calendar-start
    font-size: 24px
  @media only screen and (max-width: $bp-xl)
    margin-right: 0
    .search-container
      width: 100%
      justify-content: flex-start
      .search-field
        width: calc(100% - 0.5rem)
</style>
